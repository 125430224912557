import React from "react";
import { withPrefix } from "gatsby";

// assets
import playIcon from "../../../assets/images/icon-play.svg";

interface Props {
  backgroundMediaUrl?: string | undefined;
  backgroundMediaTitle?: string | undefined;
  videoMediaType?: "youtube" | "vimeo";
  videoMediaId?: string | undefined;
}

interface IMapping {
  [key: string]: any;
}

const videoUrl: IMapping = {
  youtube: "https://www.youtube.com/embed/",
  vimeo: "https://player.vimeo.com/video/",
  default: "https://www.youtube.com/embed/",
};

const TextMediaVideo: React.FC<Props> = (props) => {
  const {
    backgroundMediaUrl,
    backgroundMediaTitle,
    videoMediaId,
    videoMediaType,
  } = props;

  return (
    <div className="uk-inline">
      <img src={withPrefix(backgroundMediaUrl || "")} alt={backgroundMediaTitle} />
      {videoMediaId && (
        <div>
          <div className="uk-position-center uk-width-auto">
            <a
              className="c-textmedia__play"
              uk-toggle={`target: #js-modal-media-vimeo-${videoMediaId}`}
            >
              <span className="c-visually__hidden">Play video</span>
              <img src={playIcon} alt="" />
            </a>
          </div>
          <div
            id={`js-modal-media-vimeo-${videoMediaId}`}
            className="uk-flex-top"
            uk-modal="true"
          >
            <div className="uk-modal-dialog uk-width-auto uk-margin-auto-vertical">
              <button
                className="uk-modal-close-outside"
                type="button"
                uk-close="true"
              >
                <span className="c-visually__hidden">Close video</span>
              </button>
              <iframe
                src={`${videoUrl[videoMediaType || "vimeo"]}${videoMediaId}`}
                width="1280"
                height="720"
                frameBorder="0"
                uk-video="true"
                uk-responsive="true"
                title="Video"
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TextMediaVideo;
