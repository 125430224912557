import * as React from "react";

// Components
import Layout from "../components/Layouts/Layouts";
import Seo from "../components/Layouts/Seo";
import Banner from "../components/Content/Banner/Index";
import LeadIn from "../components/Content/LeadIn/Index";
import Col2 from "../components/Content/TextMedia/Col-2";

// Data
import bannerHeroItems from "../pages/static-pages/content/home.yaml";

// markup
const IndexPage: React.FC = () => {
  return (
    <Layout>
      <Seo
        title="Home"
        description="Backed by over 110 years of innovation, Conex Bänninger is the recognised leader in press fittings across the plumbing and HVAC-R industries."
      />
      <Banner type="hero" removePadding={true} data={bannerHeroItems.items} />
      <LeadIn
        brandMediaUrl="/logos/logo.svg"
        brandMediaTitle="Conex Bänninger Logo"
        brandMediaWidth="306px"
        summary=">B< Press fittings are quick and easy to install and are available in copper and copper alloy. This flame-free product range is designed with an innovative 3-point press system which ensures a secure, permanent leak-free joint and is suitable for multiple applications. >B< Press fittings are also compatible with a variety of press tools and jaws."
        paddingSize="small"
      />
      <Col2
        brandMediaUrl="/brands/logo-bpress.svg"
        brandMediaTitle="BPress"
        brandMediaWidth="170px"
        summary="Backed by over 110 years of innovation, Conex Bänninger is the recognised leader in press fittings across the plumbing and HVAC-R industries."
        backgroundMediaUrl="/products/tools/tools-approved.jpg"
        backgroundMediaTitle="Person showing tool and fittings"
        mediaPositionClass="right"
        videoMediaType="youtube"
        videoMediaId="HpI7bS6z7Qc"
      />
      <LeadIn
        section="primary"
        brandMediaUrl="/icons/icon-search-white.svg"
        brandMediaTitle="Download"
        brandMediaWidth="69px"
        summary="Find out more about the labor saving >B< Press products."
        paddingSize="small"
        buttonValue="Find out more"
        buttonUrl="/products/b-press"
      />
    </Layout>
  );
};

export default IndexPage;
