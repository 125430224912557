import React from "react";
import { withPrefix } from "gatsby";

// components
import TextMediaVideo from "./Video";

interface Props {
  section?: "default" | "muted" | "primary" | "secondary";
  heading?: string | null;
  brandMediaUrl?: string | null;
  brandMediaTitle?: string | undefined;
  brandMediaWidth?: string | undefined;
  summary?: string | null;
  backgroundMediaUrl?: string | undefined;
  backgroundMediaTitle?: string | undefined;
  videoMediaType?: "youtube" | "vimeo";
  videoMediaId?: string | undefined;
  mediaPositionClass?: string | null;
  footnote?: string | null;
  removePadding?: boolean | null;
}

const defaultProps: Props = {
  section: "default",
  mediaPositionClass: "right",
  removePadding: false,
};

const Col2: React.FC<Props> = (props) => {
  const {
    section,
    heading,
    brandMediaUrl,
    brandMediaTitle,
    brandMediaWidth,
    summary,
    backgroundMediaUrl,
    backgroundMediaTitle,
    videoMediaId,
    videoMediaType,
    mediaPositionClass,
    footnote,
    removePadding,
  } = props;

  return (
    <section
      className={`c-textmedia uk-section uk-preserve-color ${
        removePadding ? "uk-section-small" : "uk-section-large"
      } uk-section-${section}`}
    >
      <div className="uk-container">
        <div className="uk-grid uk-grid-collapse uk-child-width-1-2@s uk-grid-match">
          <div className="uk-flex-middle">
            <div className="c-textmedia__container uk-section uk-section-large">
              <div
                className={`c-textmedia__content-${mediaPositionClass}`}
                uk-scrollspy="target: > *; cls: uk-animation-slide-bottom-small; delay: 100"
              >
                {brandMediaUrl && (
                  <div
                    className="c-textmedia__brand"
                    style={{ width: brandMediaWidth }}
                  >
                    <img
                      width=""
                      height=""
                      alt={brandMediaTitle}
                      src={withPrefix(brandMediaUrl)}
                      className="uk-width-1-1"
                    />
                  </div>
                )}
                {heading && (
                  <h2 className="c-textmedia__heading uk-h5 uk-h4@m uk-h3@l uk-h2@xl">
                    {heading}
                  </h2>
                )}
                {summary && (
                  <div
                    className="uk-p@m uk-margin-top"
                    dangerouslySetInnerHTML={{ __html: summary }}
                  />
                )}
              </div>
            </div>
          </div>
          {backgroundMediaUrl && (
            <div
              className={`uk-flex-middle uk-flex-first ${
                mediaPositionClass === "left"
                  ? "uk-flex-first@s"
                  : "uk-flex-last@s"
              }`}
            >
              <div>
                <TextMediaVideo
                  videoMediaType={videoMediaType}
                  backgroundMediaUrl={backgroundMediaUrl}
                  backgroundMediaTitle={backgroundMediaTitle}
                  videoMediaId={videoMediaId}
                />
              </div>
            </div>
          )}
        </div>
        {footnote && (
          <div
            className="uk-margin-large-top uk-width-1-1 uk-width-2-3@m uk-margin-auto uk-p@s uk-text-center"
            dangerouslySetInnerHTML={{ __html: footnote }}
          />
        )}
      </div>
    </section>
  );
};

export default Col2;

Col2.defaultProps = defaultProps;
